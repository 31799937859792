import React from 'react';
import { Link } from 'react-router-dom';

export default function VerticalSolutions({ solutions }) {
    return (
        <>
            <div className='w-fit m-auto'><h1 className='w-fit text-4xl lg:font-extrabold font-bold mt-10'>Vertical Solutions</h1></div>
            <div className='flex justify-center lg:justify-between p-10 flex-wrap'>
                <div className='w-3/4'>
                    <div className='flex flex-wrap justify-evenly'>
                        {solutions.map((solution, index) => (
                            <div key={index} className='w-80 inner-shadow-example h-[42vh] rounded-3xl p-5 relative m-auto mt-10 cursor-pointer landscape:h-[42vw] md:landscape:h-[42vh]' id={solution.Heading}>
                                <img src="resources/cube white.png" alt="" className='absolute top-7 left-3 w-10' />
                                <div className='py-1 px-3 border-b-2 border-gray-300 mb-10 ml-6'>
                                    <h1 className='text-[0.95rem] text-gray-500 font-bold'>{solution.Heading}</h1>
                                </div>
                                <div className='w-56 m-auto font-light text-sm text-gray-700'>
                                    {solution.Description}
                                </div>
                                <div className='text-white text-center rounded-lg overflow-hidden absolute bottom-2 w-[18vw]'><button className=' bg-blue-600 hover:bg-yellow-300 hover:text-black w-full p-1'><Link to="/form">Order Now</Link></button></div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='w-1/4 border-black border-l-2 px-6 mx-auto mt-10 h-fit hidden lg:block'>
                    <h3 className='font-bold text-2xl'>Our Tally Modules</h3>
                    {solutions.map((solution, index) => (
                        <li key={index} className='m-3 text-gray-500 hover:text-black'>
                            <a href={"#" + solution.Heading}>{solution.Heading}</a>
                        </li>
                    ))}
                </div>
            </div>
        </>
    )
}
