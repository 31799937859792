import React from 'react'
import { Link } from 'react-router-dom'

export default function SolutionsPage() {

    const close = () => {
        document.getElementById("menues").style.display = "none";
    }
    const open = () => {
        document.getElementById("menues").style.display = "block";
    }


    let x = document.getElementById('addon');
    let y = document.getElementById('vsolution');
    const color = () => {
        x.style.backgroundColor = "#fde047"
        y.style.backgroundColor = "#ededed"
    }
    const color2 = () => {
        y.style.backgroundColor = "#fde047"
        x.style.backgroundColor = "#ededed"
    }
    return (
        <>
            <div className='hidden lg:block'>
                <div className="Header bg-[#1c1c1c] h-fit flex justify-between py-7">
                    <div className='w-fit text-white'><h1 className='text-3xl font-extrabold ml-10' style={{ fontFamily: "'Archivo Black', sans-serif" }}> <span className="text-yellow-500">JSR </span><span className="text-blue-500">P</span>rime <span className="text-blue-500">S</span>olution</h1></div>
                    <div className="w-fit text-white my-auto">
                        <ul className="flex space-x-8 mr-20 font-sans my-auto">
                            <li className="hover:text-yellow-300"><Link to="/">Home</Link></li>
                            <li className="hover:text-yellow-300"><Link to="/Solutions">Solutions</Link></li>
                            <li className="hover:text-yellow-300"><a href="/#About">About</a></li>
                            <li className="hover:text-yellow-300"><a href="/#Contact">Contact</a></li>
                            <li>|</li>
                            <li><a href="https://youtube.com/@tallymodule2.0" target="_blank"><svg stroke="currentColor" class=" hover:text-yellow-500 cursor-pointer hover:scale-150" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"></path></g></svg></a></li>
                            <li className="my-auto"><a href="https://wa.me/+919998464854" target="_blank">
                                <svg fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 308 308" height="1.3em" width="1.3em" class=" hover:text-yellow-500 cursor-pointer hover:scale-150">
                                    <g id="XMLID_468_">
                                        <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                        c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                        c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                        c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                        c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                        c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                        c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                        c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                        c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                        C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                        <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                        c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                        c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                        M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                        l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                        c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                        C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                    </g>
                                </svg>
                            </a></li>


                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <Link id='addon' to="/Solutions" className='w-1/2 text-center p-2 hover:inner-shadow-example cursor-pointer bg-[#fde047] font-bold' onClick={color} ><div>Add-Ons</div></Link>
                    <Link id='vsolution' to="/Solutions/verticalsolution" className='w-1/2 text-center p-2 hover:inner-shadow-example cursor-pointer bg-[#ededed] font-bold' onClick={color2}><div>Vertical Solutions</div></Link>
                </div>
            </div>
            <div className='lg:hidden'>
                <div className=" h-fit bg-black text-white landscape:h-fit relative flex justify-between pb-5">
                    <div className='w-fit text-white'><h1 className='text-2xl font-extrabold ml-3 mt-5'>JSR Prime Solution</h1></div>
                    <div className="w-[70vw] text-white bg-[rgb(20,20,20)] h-screen fixed z-10 ml-[30vw] hidden landscape:h-[100vw] landscape:w-[40vw] landscape:ml-[60vw] landscape:overflow-y-scroll" id="menues">
                        <div className="flex justify-end text-5xl pt-5 pr-10 z-50" onClick={close}>×</div>
                        <ul className="font-sans flex flex-col items-center h-full mt-24">
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/">Home</Link></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/Solutions">Solutions</Link></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><a href="/#About">About</a></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><a href="/#Contact">Contact</a></li>
                        </ul>
                    </div>
                    <div className="flex justify-end text-4xl pt-5 pr-10" onClick={open}>☰</div>
                </div>
                <div className='flex'>
                    <Link id='addon' to="/Solutions" className='w-1/2 text-center p-2 hover:inner-shadow-example cursor-pointer bg-[#ededed]  font-bold' onClick={color} ><div>Add-Ons</div></Link>
                    <Link id='vsolution' to="/Solutions/verticalsolution" className='w-1/2 text-center p-2 hover:inner-shadow-example cursor-pointer bg-[#ededed] font-bold' onClick={color2}><div>Vertical Solutions</div></Link>
                </div>
            </div>
        </>
    )
}
