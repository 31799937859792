import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <main>
      <div className="m-auto w-fit mt-20 underline font-bold text-xl cursor-pointer"><Link to="/Loginform">Admin</Link></div>
      <footer className="bg-gray-900 text-white py-2 mt-16 text-sm">
          <div className="m-auto w-fit">Copyright © 2024 jsrprimesolution.com | Privacy Policy</div>
      </footer>
    </main>
  )
}
