import React from 'react'
import SolutionsPage from './SolutionsPage'
import { Outlet } from 'react-router-dom'
import Chatbox from './Chatbox'

export default function SolutionsLayout() {
  return (
    <>
        <SolutionsPage/>
        <Outlet/>
        <Chatbox/>
    </>
  )
}
