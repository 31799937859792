import React from 'react';
import FrontPage from "./FrontPage";
import Solutions from './Solutions';
import Chatbox from './Chatbox';
import Clients from './Clients';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import TopButton from './TopButton';

export default function Layout({vs}) {
    return (
        <>
            <FrontPage />
            <Solutions solutions={vs} />
            <Clients />
            <About />
            <Contact />
            <Footer />
            <TopButton />
            <Chatbox />
        </>
    )
}
